import React from 'react';
import CarouselElegant from '../../components/CarouselElegant';
import HeaderSection from './HeaderSection';
import './CarouselSection.css'; // Asegúrate de tener un archivo CSS para los estilos

const handleSecondaryImageClick = () => {
  alert('Mandar a WhatsApp');
};

const carouselItems = [
  {
    mainImage: '/images/banner_1/salon_2.png',
    secondaryImage: '/images/banner_1/whatsapp_button_1.png',
    position: { bottom: '100px', left: '200px' },
    size: { width: '400px' },
    onClick: handleSecondaryImageClick,
  },
  {
    mainImage: '/images/banner_1/FONDO2.png',
    secondaryImage: 'banner3.png',
    position: { bottom: '20px', right: '20px' },
    onClick: null,
  },
];

console.log('CarouselSection renderizado');

const CarouselSection: React.FC = () => {
  return (
    <>
      <div className="carousel-container">
        <CarouselElegant items={carouselItems} />
      </div>
      {/*<HeaderSection />*/}
    </>
  );
};

export default CarouselSection;
