import React from 'react';
import { Box, Container, Grid, Typography, IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: '#ffffff', py: 5 }}>
      <Container maxWidth="lg">
        <Grid container spacing={3} alignItems="center">
          {/* Logotipo y texto a la izquierda */}
          <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
            <Box sx={{ maxWidth: '200px', marginBottom: '20px' }}>
              <img src="/images/footer/logo_nova_azul.png" alt="Nova Logo" style={{ width: '100%', display: 'block', marginBottom: '0' }} />
              <Typography sx={{ color: '#FFA500', fontSize: '1rem', mt: 0, textAlign: 'left', whiteSpace: 'nowrap' }}>
                ASESORAMIENTO INTEGRAL
              </Typography>
            </Box>
            <Typography variant="body1" color="textPrimary" sx={{ textAlign: 'left' }}>
              Brindamos servicios de Ingeniería empresarial de calidad haciendo el uso de herramientas y métodos adecuados a las necesidades de su empresa o negocio.
            </Typography>
          </Grid>

          {/* Información de contacto y redes sociales a la derecha */}
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
              <IconButton href="https://wa.me/1234567890" target="_blank">
                <WhatsAppIcon sx={{ fontSize: 40, color: '#25D366' }} />
              </IconButton>
              <Typography variant="h6" color="textPrimary">
                626 - 96584
              </Typography>
            </Box>
            <Box>
              <IconButton href="https://www.facebook.com/tu-pagina" target="_blank">
                <FacebookIcon sx={{ fontSize: 40, color: '#4267B2' }} />
              </IconButton>
              <IconButton href="https://www.instagram.com/tu-pagina" target="_blank">
                <InstagramIcon sx={{ fontSize: 40, color: '#E1306C' }} />
              </IconButton>
              <IconButton href="https://www.tiktok.com/@tu-pagina" target="_blank">
                <img src="/images/footer/tik-tok.png" alt="TikTok" style={{ width: 40, height: 40 }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
