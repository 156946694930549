import React, { useEffect, useRef } from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PersonIcon from '@mui/icons-material/Person';

interface MessagesProps {
  responses: { text: string, sender: 'user' | 'bot' }[];
  botTyping: boolean; // Asegúrate de definir esta prop en la interfaz
}

const Messages: React.FC<MessagesProps> = ({ responses, botTyping }) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [responses, botTyping]);

  return (
    <Box sx={{ overflowY: 'auto', flexGrow: 1, p: 2 }}>
      {responses.map((res, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: res.sender === 'bot' ? 'flex-start' : 'flex-end',
            mb: 1,
          }}
        >
          {res.sender === 'bot' && <Avatar sx={{ mr: 1, backgroundColor: '#3c2b1e' }}>< PersonIcon/></Avatar>}
          <Box
            sx={{
              backgroundColor: res.sender === 'bot' ? '#e7f6ff' : '#f0f0f0',
              color: 'black',
              borderRadius: 2,
              padding: '10px',
              maxWidth: '70%',
              textAlign: res.sender === 'bot' ? 'left' : 'right',
            }}
          >
            {res.text}
          </Box>
          {res.sender === 'user' && <Avatar sx={{ ml: 1, backgroundColor: 'green' }}><ChatBubbleOutlineIcon /></Avatar>}
        </Box>
      ))}
      {botTyping && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            mb: 1,
          }}
        >
          <Avatar sx={{ mr: 1, backgroundColor: '#3c2b1e' }}><ChatBubbleOutlineIcon /></Avatar>
          <Box
            sx={{
              backgroundColor: '#e1ffc7', // poner verde
              color: 'black',
              borderRadius: 2,
              padding: '10px',
              maxWidth: '70%',
              textAlign: 'left',
            }}
          >
            <Typography component="span" sx={{ fontStyle: 'italic' }}>
              Escribiendo...
            </Typography>
          </Box>
        </Box>
      )}
      <div ref={messagesEndRef} />
    </Box>
  );
};

export default Messages;
