import React from 'react';
import { Box, Button } from '@mui/material';

interface PredefinedMessagesProps {
  messages: string[]; // Lista de mensajes predefinidos que recibirá como prop
  onMessageClick: (msg: string) => void;
}

const PredefinedMessages: React.FC<PredefinedMessagesProps> = ({ messages, onMessageClick }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 2, gap: 1 }}>
      {messages.map((msg, index) => (
        <Button
          key={index}
          variant="outlined"
          onClick={() => onMessageClick(msg)}
          sx={{
            fontSize: '10px',
            width: messages.length % 2 !== 0 && index === messages.length - 1 ? '100%' : '48%',
            padding: '5px 10px',
          }}
        >
          {msg}
        </Button>
      ))}
    </Box>
  );
};

export default PredefinedMessages;
