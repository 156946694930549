import React from 'react';
import { Container, Box, Typography, Divider, Button, Grid } from '@mui/material';

const ServicesAndNormsPage: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Servicios y Normas de Cumplimiento
      </Typography>

      <Typography variant="body1" align="center" sx={{ mb: 4 }}>
        En NOVA Asesoramiento Integral, nos dedicamos a proporcionar soluciones tecnológicas y empresariales que abarcan desde el desarrollo de software hasta la optimización de procesos y estrategias comerciales. Nuestro objetivo es ayudar a empresas de todos los tamaños a alcanzar sus metas a través de la innovación tecnológica y la consultoría especializada.
      </Typography>

      <Box sx={{ mb: 6 }}>
        <Typography variant="h5" gutterBottom>
          Servicios Profesionales
        </Typography>
        <Divider sx={{ mb: 2, width: '50px' }} />
        <Typography variant="body1" paragraph>
          Nuestra gama de servicios está diseñada para satisfacer las necesidades tecnológicas de empresas modernas. Con un enfoque centrado en el cliente, nuestro equipo multidisciplinario se especializa en:
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Desarrollo de Software Personalizado
              </Typography>
              <Typography variant="body2" paragraph>
                Creamos aplicaciones web y móviles adaptadas a las necesidades específicas de cada cliente, garantizando escalabilidad, seguridad y facilidad de uso.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Consultoría Empresarial
              </Typography>
              <Typography variant="body2" paragraph>
                Ayudamos a las empresas a optimizar sus procesos internos mediante la automatización, el análisis de datos y la integración de sistemas para una mayor eficiencia.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Estrategia de Marketing Integral
              </Typography>
              <Typography variant="body2" paragraph>
                Desarrollamos estrategias de marketing digital que conectan a nuestros clientes con su público objetivo, generando mayores conversiones y aumentando la visibilidad.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: 6 }}>
        <Typography variant="h5" gutterBottom>
          Cumplimiento y Protección de Datos
        </Typography>
        <Divider sx={{ mb: 2, width: '50px' }} />
        <Typography variant="body1" paragraph>
          La protección de los datos de nuestros clientes es una prioridad. Cumplimos con las normativas internacionales como el Reglamento General de Protección de Datos (GDPR) y otras legislaciones locales para garantizar que la información se maneje de manera ética y segura.
        </Typography>
        <Typography variant="body1" paragraph>
          Implementamos cifrado de extremo a extremo para proteger la información durante su transmisión y almacenamiento. Además, llevamos a cabo auditorías regulares para garantizar que nuestros sistemas sean seguros y estén actualizados.
        </Typography>
        <Typography variant="body1" paragraph>
          Nos comprometemos a notificar a nuestros clientes de manera inmediata ante cualquier incidente de seguridad y a tomar medidas proactivas para mitigar posibles riesgos.
        </Typography>
      </Box>

      <Box sx={{ mb: 6 }}>
        <Typography variant="h5" gutterBottom>
          Compromiso con la Información del Cliente
        </Typography>
        <Divider sx={{ mb: 2, width: '50px' }} />
        <Typography variant="body1" paragraph>
          La confianza es fundamental en nuestra relación con los clientes. Por ello, garantizamos que la información proporcionada será manejada con estricta confidencialidad y utilizada únicamente para los fines acordados en los contratos de servicio.
        </Typography>
        <Typography variant="body1" paragraph>
          No compartiremos ni venderemos la información de nuestros clientes a terceros sin su consentimiento explícito. Además, proporcionamos mecanismos para que los clientes gestionen sus datos de manera autónoma, incluyendo la eliminación de los mismos cuando sea necesario.
        </Typography>
        <Typography variant="body1" paragraph>
          Continuamente mejoramos nuestras políticas de privacidad y seguridad para garantizar que cumplimos con las mejores prácticas de la industria, protegiendo siempre la información de nuestros clientes.
        </Typography>
      </Box>

      <Box textAlign="center" sx={{ mt: 4 }}>
        <Button variant="contained" color="primary" size="large">
          Contáctanos para más información
        </Button>
      </Box>
    </Container>
  );
};

export default ServicesAndNormsPage;
