import React from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';

interface CarouselGeneralProps {
  items: React.ReactNode[];
}

const CarouselGeneral: React.FC<CarouselGeneralProps> = ({ items }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,  // Activar desplazamiento automático
    autoplaySpeed: 2000,  // Velocidad de desplazamiento automático
  };

  return (
    <Slider {...settings}>
      {items.map((item, index) => (
        <Box key={index} sx={{ px: 2 }}>
          {item}
        </Box>
      ))}
    </Slider>
  );
};

export default CarouselGeneral;
