import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HomePage from './pages/homePage/HomePage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './pages/homePage/Layout';
import ServicesAndNormsPage from './pages/homePage/ServicesAndNormas';
import HeaderSection from './pages/homePage/HeaderSection';

// Crear tema global
const theme = createTheme({
  palette: {
    primary: {
      main: '#ff6f00',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    h4: {
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
      <HeaderSection/>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* Ruta para la página de inicio */}
            <Route index element={<HomePage />} />
            
            {/* Ruta para la nueva página de Servicios y Normas */}
            <Route path="services-and-norms" element={<ServicesAndNormsPage />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
