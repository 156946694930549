import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Chat from '../../chat/Chat';

const Layout: React.FC = () => {
  return (
    <div>
      {/* Aquí va tu Header si lo tienes */}
      <Outlet />
      <Footer />
      <Chat />
    </div>
  );
};

export default Layout;
