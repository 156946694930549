import React from 'react';
import { Box, Typography } from '@mui/material';

interface CustomCardProps {
  topText: string;
  bottomText: string;
  topBgColor: string;
  bottomBgColor: string;
  topTextColor: string;
  bottomTextColor: string;
}

const CustomCard: React.FC<CustomCardProps> = ({
  topText,
  bottomText,
  topBgColor,
  bottomBgColor,
  topTextColor,
  bottomTextColor,
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: 'fit-content',
        marginBottom: 0, // Ajustar margen si es necesario
      }}
    >
      <Box
        sx={{
          backgroundColor: bottomBgColor,
          color: bottomTextColor,
          padding: '16px 20px', // Aumentar padding para hacer el contenedor más grande
          borderRadius: '10px', // Margen más grueso
          border: `4px solid ${topBgColor}`, // Aumentar grosor del borde
          position: 'relative',
        }}
      >
        <Typography variant="h6" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}> {/* Aumentar tamaño y grosor de la fuente */}
          {bottomText}
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: topBgColor,
          color: topTextColor, // Usar el color de texto proporcionado
          padding: '8px 16px', // Aumentar padding
          borderRadius: '12px', // Margen más grueso
          border: `4px solid ${bottomBgColor}`, // Aumentar grosor del borde
          position: 'absolute',
          top: '-24px', // Ajustar para la superposición
          left: '0', // Alinear ambos elementos a la izquierda
          zIndex: 1,
        }}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            fontSize: '1.2rem', 
            fontWeight: 'bold', 
            lineHeight: '1', 
            fontFamily: '"Times New Roman", serif', // Cambiar la fuente a Times New Roman
          }}
        >
          {topText}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomCard;
