import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import Messages from './Messages';
import PredefinedMessages from './PredefinedMessages';

interface ChatWindowProps {
  onClose: () => void;
  isVisible: boolean;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ onClose, isVisible }) => {
  const [message, setMessage] = useState('');
  const [responses, setResponses] = useState<{ text: string, sender: 'user' | 'bot' }[]>([
    { text: 'Hola, ¿en qué puedo ayudarte?', sender: 'bot' }
  ]);
  const [botTyping, setBotTyping] = useState(false);
  const [predefinedMessages, setPredefinedMessages] = useState<string[]>([
    "¿Cómo puedo contactar con soporte?",
    "¿Cuáles son sus horarios de atención?",
    "¿Dónde puedo encontrar más información?"
  ]);

  const handleSend = () => {
    if (message.trim()) {
      setResponses([...responses, { text: message, sender: 'user' }]);
      setMessage('');
      simulateBotResponse('Gracias por tu mensaje, lo estamos revisando.');
    }
  };

  const simulateBotResponse = (botMessage: string) => {
    setBotTyping(true);

    setTimeout(() => {
      setBotTyping(false);
      setResponses(prevResponses => [
        ...prevResponses,
        { text: botMessage, sender: 'bot' }
      ]);
      // Actualizar los mensajes predefinidos en función de la respuesta del bot
      simulateFetchNewMessages();
    }, 3000);
  };

  const simulateFetchNewMessages = () => {
    // Aquí puedes simular una llamada a un endpoint para obtener nuevos mensajes predefinidos
    const newMessages = [
      "¿Cuál es el tiempo de respuesta?",
      "¿Dónde puedo ver mi historial?",
      "¿Cómo actualizo mis datos?"
    ];
    setPredefinedMessages(newMessages);
  };

  const handlePredefinedMessageClick = (msg: string) => {
    if (!botTyping) {
      setResponses([...responses, { text: msg, sender: 'user' }]);
      simulateBotResponse('Déjame revisarlo...');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !botTyping) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleClearChat = () => {
    setResponses([{ text: 'Hola, ¿en qué puedo ayudarte?', sender: 'bot' }]);
    setPredefinedMessages([
      "¿Cómo puedo contactar con soporte?",
      "¿Cuáles son sus horarios de atención?",
      "¿Dónde puedo encontrar más información?"
    ]); // Reiniciar a los mensajes predefinidos originales
  };

  if (!isVisible) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 80,
        right: 20,
        width: 400,
        height: 600,
        backgroundColor: 'white',
        boxShadow: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ p: 2, backgroundColor: '#87CEEB', color: 'blue', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Chat de soporte</span>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={handleClearChat}
          size="small"
          sx={{ color: 'white', borderColor: 'white' }}
        >
          Limpiar Chat
        </Button>
      </Box>
      <Messages responses={responses} botTyping={botTyping} />
      {!botTyping && (
        <PredefinedMessages messages={predefinedMessages} onMessageClick={handlePredefinedMessageClick} />
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Escribe un mensaje..."
          onKeyPress={handleKeyPress}
          disabled={botTyping}
        />
        <IconButton
          color="success"
          onClick={handleSend}
          sx={{ marginLeft: 1 }}
          disabled={botTyping}
        >
          <SendIcon />
        </IconButton>
      </Box>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={onClose}
        sx={{ borderRadius: '0 0 8px 8px' }}
      >
        Cerrar Chat
      </Button>
    </Box>
  );
};

export default ChatWindow;
