import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Card1Props {
  image: string;
  title: string;
  description: string;
  url?: string;
  onClick?: () => void;
  expanded?: boolean;
  onClose?: () => void;
}

const Card1: React.FC<Card1Props> = ({ image, title, description, url, onClick, expanded = false, onClose }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [hovered, setHovered] = useState(false);
  const [index, setIndex] = useState(0);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (!hovered && !expanded) {
      interval = setInterval(() => {
        setDisplayedText((prev) => prev + description.charAt(index));
        setIndex((prev) => prev + 1);
        if (index >= description.length) {
          setDisplayedText('');
          setIndex(0);
        }
      }, 50);
    } else if (expanded) {
      setDisplayedText(description);
    }

    return () => clearInterval(interval);
  }, [description, index, hovered, expanded]);

  const handleMouseEnter = () => {
    if (!expanded) {
      setHovered(true);
      setDisplayedText(description);
    }
  };

  const handleMouseLeave = () => {
    if (!expanded) {
      setHovered(false);
      setDisplayedText('');
      setIndex(0);
    }
  };

  const handleButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (url) {
      window.open(url, '_blank');
    } else {
      alert('Próximamente');
    }
  };

  const handleCardClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick?.();
  };

  return (
    <Box 
      ref={cardRef}
      sx={{ 
        maxWidth: expanded ? '100%' : 345, 
        margin: expanded ? 'auto' : '0 auto', 
        cursor: 'pointer', 
        transition: 'transform 0.3s ease-in-out',
        '&:hover': { transform: expanded ? 'none' : 'scale(1.05)' },
        position: 'relative',
        border: '4px solid black',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
        borderRadius: '16px',
        backgroundColor: '#fff',
        overflow: 'hidden',
      }} 
      onClick={expanded ? undefined : handleCardClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box 
        sx={{ 
          width: '100%', 
          height: 'auto', 
          overflow: 'hidden',
        }}
      >
        <img
          src={image}
          alt={title}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'block',
            borderRadius: 'inherit',
          }}
        />
      </Box>
      <Box sx={{ p: 2, textAlign: 'left' }}> {/* Cambié textAlign a 'left' */}
        <Typography variant="h5" component="div" gutterBottom sx={{ textAlign: 'left' }}> {/* Justificación a la izquierda */}
          {title}
        </Typography>
        {expanded && (
          <Box sx={{ mt: 2, textAlign: 'left', p: 3}} >
            <Button variant="contained" color="primary" onClick={handleButtonClick}>
              PRUEBA YA!
            </Button>
            <IconButton 
              aria-label="close" 
              onClick={(event) => { event.stopPropagation(); onClose?.(); }} 
              sx={{ 
                position: 'absolute', 
                top: 8, 
                right: 8 
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        <Box sx={{ overflow: 'hidden', whiteSpace: 'pre-wrap', height: hovered || expanded ? 'auto' : '3em', transition: 'height 0.3s ease-in-out' }}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            sx={{
              display: 'inline-block',
              width: '100%',
              textAlign: 'left',  // Justificación a la izquierda
              whiteSpace: 'pre-wrap',
            }}
          >
            {expanded ? description : displayedText}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Card1;
