import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import ChatWindow from './ChatWindow';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

const Chat: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [attentionNeeded, setAttentionNeeded] = useState(false);

  const toggleChat = () => {
    setOpen(!open);
    setAttentionNeeded(false); // Detener animación si el chat está abierto
  };

  useEffect(() => {
    const attentionInterval = setInterval(() => {
      if (!open) {
        setAttentionNeeded(true);
        setTimeout(() => setAttentionNeeded(false), 5000); // Duración de la burbuja "¡Hola!"
      }
    }, 10000); // Cada 10 segundos si el chat no está abierto

    return () => clearInterval(attentionInterval);
  }, [open]);

  return (
    <Box sx={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1000 }}>
      {!open && (
        <Box
          sx={{
            position: 'relative',
            animation: attentionNeeded ? 'wiggle 1s ease-in-out' : 'none',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={toggleChat}
            sx={{
              borderRadius: '50px',
              backgroundColor: '#FFA500',
              color: '#0D1B2A',
              padding: '10px 20px',
              textTransform: 'none',
              boxShadow: 3,
              fontWight: "bold"
            }}
            startIcon={<ChatBubbleOutlineIcon />}
          >
            Chatea con nosotros
          </Button>
          {attentionNeeded && (
            <Box
              sx={{
                position: 'absolute',
                top: -40,
                right: 0,
                backgroundColor: 'white',
                borderRadius: '8px',
                padding: '5px 10px',
                boxShadow: 3,
              }}
            >
              ¡Hola!
            </Box>
          )}
        </Box>
      )}
      <ChatWindow onClose={toggleChat} isVisible={open} />
    </Box>
  );
};

export default Chat;
