import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import CarouselGeneral from '../../components/CarouselGeneral';
import Card1 from '../../components/Card1';

const ProjectsSection: React.FC = () => {
  const [expandedCard, setExpandedCard] = useState<string | null>(null);

  const handleCardClick = (title: string) => {
    setExpandedCard((prevTitle) => (prevTitle === title ? null : title));
  };

  const handleCardClose = () => {
    setExpandedCard(null);
  };

  const projects = [
    { title: 'SALONES Y PELUQUERIAS', image: '/images/proyectos/salones_foto.png', description: '• CONTROL Y SEGUIMIENTO DE PRODUCTOS Y SERVICIOS.\n• CONTROL DE SUELDO POR TIEMPO PERSONALIZADO.\n• CONTROL DE COMISIONES POR VENTA O SERVICIO REALIZADO.\n• CANCELAR SUELDOS POR FRECUENCIA DE DÍAS TRABAJADOS.\n• CANCELAR SUELDO DEL TOTAL DE COMISIONES ACUMULADAS.\n• AGREGAR PERSONAL Y CONTROL DE ASISTENCIA.\n• CREAR CUENTA DE ADMINISTRADOR DE CAJA.\n• ANÁLISIS DE DATOS PARA LA TOMA DE DECISIONES.', url: 'https://salones.novaasesores.com/' },
    { title: 'ATENCION AL CLIENTE', image: '/images/proyectos/software_atencion_proxima.png', description: '', url: '' },
    { title: 'GESTION SUPERMERCADOS', image: '/images/proyectos/software_supermercado_proxima.png', description: '', url: '' },
  ];

  const items = projects.map(project => (
    <Card1 
      key={project.title} 
      image={project.image} 
      title={project.title} 
      description={project.description} 
      url={project.url}
      onClick={() => handleCardClick(project.title)} 
      expanded={expandedCard === project.title}
      onClose={handleCardClose}
    />
  ));

  return (
    <Box>
      <Typography variant="h4" align="center" sx={{ mt: 5, mb: 3 }}>
        Algunos de nuestros proyectos
      </Typography>
      <CarouselGeneral items={items} />
    </Box>
  );
};

export default ProjectsSection;
