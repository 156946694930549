import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const Logo = styled('img')({
  height: 'auto',
  width: 'auto',
  maxWidth: '100px',
  objectFit: 'contain',
});

const Menu = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  fontSize: '1.2rem',
  alignSelf: 'center',
  marginRight: '20px', // Espacio entre elementos del menú
}));

const HeaderContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100px',
  zIndex: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 20px',
  boxSizing: 'border-box',
});

const HeaderSection: React.FC = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleServicesClick = () => {
    navigate('/services-and-norms');
  };

  const logoSrc = "/images/nova/logo_nova_2_fuego.png";

  return (
    <HeaderContainer>
      <Logo 
        src={logoSrc} 
        alt="Logo" 
        onClick={handleLogoClick} 
        style={{ cursor: 'pointer' }}
      />
      <div style={{ display: 'flex' }}>
        <Menu variant="h6" onClick={handleLogoClick}>
          INICIO
        </Menu>
        <Menu variant="h6" onClick={handleServicesClick}>
          SERVICIOS Y NORMAS
        </Menu>
      </div>
    </HeaderContainer>
  );
};

export default HeaderSection;
