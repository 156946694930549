import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import CustomCard from '../../components/CustomCard';

const CorporateInfoSection: React.FC = () => {
  console.log('CorporateInfoSection renderizado');

  const cardData = [
    { topText: 'PROCESO', bottomText: 'Venta - Posts venta' },
    { topText: 'ESTRATEGIAS', bottomText: 'Marketing integral' },
    { topText: 'DESARROLLO', bottomText: 'Software - páginas web' },
    // Add more data as needed, total of 23 cards
  ];

  const cardComponents = cardData.map((data, index) => (
    <Grid item key={index} xs={12} sm={6} md={4}> {/* Ajuste de tamaño responsivo */}
      <CustomCard
        topText={data.topText}
        bottomText={data.bottomText}
        topBgColor="#FFA500"
        bottomBgColor="#0D1B2A"
        topTextColor="#FFFFFF"
        bottomTextColor="#FFFFFF"
      />
    </Grid>
  ));

  return (
    <Box sx={{ padding: '40px 20px' }}> {/* Espacio alrededor del contenido */}
      <Typography variant="h4" align="center" gutterBottom sx={{ marginBottom: '40px', 
                  fontFamily: '"Times New Roman", serif'}}
      >
        SOLUCIONES DE CALIDAD
      </Typography>
      <Grid container justifyContent="center" spacing={4}> {/* Ajuste de spacing */}
        {cardComponents}
      </Grid>
    </Box>
  );
};

export default CorporateInfoSection;
