import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, TextField, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ContactSectionv2: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Aquí podrías manejar el envío del formulario o enviar un mensaje por WhatsApp.
    console.log("Formulario enviado");
  };

  return (
    <>
      {/* Sección de la imagen de fondo */}
      <Box
        onClick={handleOpen}
        sx={{
          backgroundImage: `url(/images/servicios/contacto_1.png)`,
          backgroundSize: 'contain',  // Ajuste de la imagen para que no se deforme
          backgroundPosition: 'center',  // Centra la imagen
          backgroundRepeat: 'no-repeat',  // Evita que la imagen se repita
          minHeight: '600px',  // Mantiene la altura mínima
          height: 'auto',  // Permite que la altura se ajuste según el contenido
          width: '100%',  // Asegura que ocupe todo el ancho disponible
          position: 'relative',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(13, 27, 42, 0.1)',  // Filtro semitransparente sobre la imagen
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            color: '#FFA500',
          }}
        >
          {/*<Typography variant="h3" sx={{ fontWeight: 'bold', textShadow: '1px 1px 2px #000' }}>
            ¡Conoce mejor a tu empresa o negocio!
          </Typography>
          <Typography variant="h5" sx={{ mt: 2, color: '#FFA500', textShadow: '1px 1px 2px #000' }}>
            Te diseñamos el modelo de negocio ¡Gratuitamente!
          </Typography>
          <Button 
            variant="contained"
            sx={{
              backgroundColor: '#FFA500',
              color: '#0D1B2A',
              borderRadius: '30px',
              padding: '10px 20px',
              fontSize: '1.1rem',
              mt: 4,
              '&:hover': {
                backgroundColor: '#e69500',
              }
            }}
          >
            ENVIAR CONSULTA
          </Button>*/}
        </Box>
      </Box>

      {/* Ventana modal del formulario */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography variant="h5" align="center">
            Enviar Consulta
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}
          >
            <TextField 
              label="Nombre" 
              variant="outlined" 
              fullWidth 
              required 
            />
            <TextField 
              label="Empresa o Negocio" 
              variant="outlined" 
              fullWidth 
              required 
            />
            <TextField 
              label="Email" 
              variant="outlined" 
              fullWidth 
              required 
              type="email"
            />
            <TextField 
              label="WhatsApp" 
              variant="outlined" 
              fullWidth 
              required 
            />
            <TextField 
              label="Consulta" 
              variant="outlined" 
              fullWidth 
              multiline 
              rows={4}
              required
            />
            <Button 
              type="submit"
              variant="contained"
              sx={{
                mt: 2,
                backgroundColor: '#FFA500',
                color: '#0D1B2A',
                padding: '10px',
                fontSize: '1.1rem',
                borderRadius: '20px',
                '&:hover': {
                  backgroundColor: '#e69500',
                },
              }}
            >
              ENVIAR CONSULTA
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContactSectionv2;
