import React from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';

interface CarouselItem {
  mainImage: string;
  secondaryImage: string | null;
  position: { top?: string; bottom?: string; right?: string; left?: string };
  size?: { width: string };
  onClick?: (() => void) | null;
}

interface CarouselElegantProps {
  items: CarouselItem[];
}

const CarouselElegant: React.FC<CarouselElegantProps> = ({ items }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Slider {...settings}>
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            width: '100%', // Usamos 100% en lugar de 100vw para evitar desbordamientos
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {/* Imagen principal */}
          <img
            src={item.mainImage}
            alt={`carousel-${index}`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'fill', // Se ajusta al contenedor
            }}
          />

          {/* Imagen secundaria */}
          {item.secondaryImage && (
            <img
              src={item.secondaryImage}
              alt={`secondary-${index}`}
              style={{
                position: 'absolute',
                ...item.position,
                top: '70%', // Mueve la imagen secundaria hacia una posición más baja
                width: '30vw', // Haz la imagen secundaria más grande pero proporcional
                height: 'auto',
                objectFit: 'contain',
                cursor: 'pointer',
                left: '20%',
              }}
              onClick={item.onClick || (() => {})}
            />
          )}
        </Box>
      ))}
    </Slider>
  );
};

export default CarouselElegant;
