import React from 'react';
import { Box, Container } from '@mui/material';
import CarouselSection from './CarouselSection';
import CorporateInfoSection from './CorporateInfoSection';
import ProjectsSection from './ProjectsSection';
import ContactSectionv2 from './ContactSectionv2';

const HomePage: React.FC = () => {
  return (
    <Box>
      <Box sx={{ width: '100%', position: 'relative', zIndex: 1 }}>
        <CarouselSection />
      </Box>
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <CorporateInfoSection />
        <ProjectsSection />
      </Container>
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <ContactSectionv2 />
      </Box>
           {/*<Container maxWidth="lg" sx={{ mt: 5 }}>
        <ClientsSection />
      </Container>*/}
      {/*<Box sx={{ width: '100%' }}>
        <ServicesSection />
      </Box>*/}
      {/*<Box>
        <ContactSection />
      </Box>*/}
 
    </Box>
  );
};

export default HomePage;
